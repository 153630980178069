import React from "react";
import Slide from "react-reveal/Slide";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import Layout from "../../components/Layout";
import { H1 } from "../../components/Typography";
import Underline from "../../components/Underline";
import Faq from "../../components/Faq";
import { useFaqs } from "../../hooks/useFaqs";
import useSiteMetadata from "../../components/SiteMetadata";

const seo = {
  title: "Frequently Asked Questions and answers on Cordyceps Mushrooms.",
  description:
    "Get answers to all your questions on Cordycpes Mushrooms. Find out how to consume Cordyceps, benefits of cordyceps & other related questions."
};

export default function FAQPage() {
  const allFaqs = useFaqs();
  const { siteUrl } = useSiteMetadata();

  return (
    <Layout whiteHeader>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        openGraph={{
          url: `${siteUrl}/frequently-asked-questions`,
          title: seo.title,
          description: seo.description,
          site_name: "MycoForest"
        }}
      />
      <section>
        <div className="container">
          <H1 alignCenter>Frequently Asked Questions</H1>
          <Underline />
          <div className="content">
            <div className="sidebar">
              <ul>
                <li>FAQ Categories</li>
                {allFaqs.map(({ category }, index) => (
                  <li key={index}>
                    <a href={`#${category}`}>{category}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="faqs">
              {allFaqs.map(({ category, faqs }) => (
                <div id={category} className="category" key={category}>
                  <h4>{category}</h4>
                  <ul>
                    {faqs
                      .sort((a, b) => a.position - b.position)
                      .map((faq, index) => (
                        <Slide bottom key={index}>
                          <Faq faq={faq} key={index} />
                        </Slide>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <style jsx>{`
          section {
            padding: 32px;
          }
          .container {
            max-width: 1278px;
            margin: 0 auto;
          }
          .content {
            display: flex;
          }
          .sidebar {
            margin-right: 32px;
          }

          .sidebar ul {
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
            width: 256px;
            border: 1px solid #f7fafc;
            border-radius: 8px;
            list-style: none;
            margin: 0;
            padding: 0;
            position: sticky;
            top: 100px;
          }

          .sidebar ul li:first-child {
            color: #718096;
            padding: 8px;
            font-weight: 700;
            font-family: "Open Sans", sans-serif;
          }

          .sidebar ul li:not(:first-child) {
            border-bottom: 1px solid #cbd5e0;
            font-family: "Lato", sans-serif;
          }

          .sidebar ul li:last-child {
            border-bottom: none;
          }

          .sidebar ul li a {
            text-transform: capitalize;
            color: #ed8936;
            padding: 12px;
            display: block;
          }

          .sidebar ul li a:hover {
            background-color: #f7fafc;
          }

          .category {
            margin-bottom: 80px;
            padding: 0 16px;
          }

          .category h4 {
            text-transform: uppercase;
            font-size: 16px;
            color: #a0aec0;
            font-family: "Open Sans", sans-serif;
            margin-top: 0;
          }
          .category ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }

          @media (max-width: 740px) {
            section {
              padding: 32px 0;
            }
            .sidebar {
              display: none;
            }
          }
        `}</style>
      </section>
    </Layout>
  );
}
